<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('ChatbotQuestions')"
    @cancel="onCancel('ChatbotQuestions')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'ChatbotQuestionsCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'ChatbotQuestions',
      pageHeader: 'Создание вопроса для чат-бота',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Вопросы для чат-бота',
          route: { name: 'ChatbotQuestions' },
        },
        {
          text: 'Создание вопроса для чат-бота',
        },
      ],
      initialForm: {
        questionText: null,
        baseName: null,
        answerId: null,
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      chatbotAnswers: (state) => state.common.chatbotAnswers.data,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'questionText',
              label: 'Текст вопроса',
            },
            {
              type: 'text',
              key: 'baseName',
              label: 'База данных',
            },
            {
              type: 'select',
              key: 'answerId',
              label: 'Ответ',
              list: this.chatbotAnswers,
              attributes: {
                clearable: true,
              },
            },
          ],
        },
      ];
    },
  },

  watch: {
    'form.baseName': {
      handler(newValue, oldValue) {
        if (!newValue) return (this.form.answerId = null);

        if (newValue && newValue !== oldValue) {
          clearTimeout(this.delay);

          this.delay = setTimeout(() => {
            this.getCommonList({
              name: 'ChatbotAnswers',
              params: {
                basename: newValue,
              },
            });
          }, 1000);
        }
      },
    },
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
